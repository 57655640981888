
import { defineComponent } from "vue";
// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { mapState } from "vuex";
import Globals from "@/views/common/Globals.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import store from "@/store";

export default defineComponent({
  name: "profile",
  mixins: [Globals],
  components: {
    // Dropdown3,
  },
  data() {
    return {
      organizationRoles: "",
      customerRoles: "",
    };
  },
  computed: {
    ...mapState([
      "email",
      "emailVerified",
      "phoneNumber",
      "token",
      "phoneNumberVerified",
      "orgRole",
      "userRole",
    ]),
  },
  mounted() {
    let user = store.getters.currentUser;
    if (Object.keys(user).length == 0) {
      router.push({ path: "/sign-in" });
    }

    for (let roles in this.orgRole) {
      if (this.orgRole[roles] == true) {
        this.organizationRoles = this.organizationRoles + roles + ",";
      }
    }
    this.organizationRoles = this.organizationRoles.substring(
      0,
      this.organizationRoles.length - 1
    );

    for (let roles in this.userRole) {
      if (this.userRole[roles] == true) {
        this.customerRoles = this.customerRoles + roles + ",";
      }
    }
    this.customerRoles = this.customerRoles.substring(
      0,
      this.customerRoles.length - 1
    );
    setCurrentPageBreadcrumbs("Profile", ["Account"]);
  },
});
